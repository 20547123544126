import React, { useState, useEffect } from 'react';

const ClockDisplay = () => {
    const [time, setTime] = useState(null);
    const [date, setDate] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const hours = String(now.getHours() % 12 || 12).padStart(2, '0'); // Convert to 12-hour format with leading zero
            const minutes = String(now.getMinutes()).padStart(2, '0'); // Add leading zero to minutes
            const ampm = now.getHours() >= 12 ? 'PM' : 'AM';
            const day = now.toLocaleString('en-US', { weekday: 'long' });
            const month = now.toLocaleString('en-US', { month: 'long' });
            const dayNumber = now.getDate();

            setTime(`${hours}:${minutes} ${ampm}`);
            setDate(`${day}, ${month} ${dayNumber}`);
        };

        updateTime();
        const intervalId = setInterval(updateTime, 1000); // Update every second

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    return (
        <div className="absolute top-2 left-4 text-white text-center">
            {time && (
                <div className="text-4xl md:text-6xl font-bold leading-none flex justify-center">
                    <span>{time.split(':')[0]}</span>
                    <span className="block text-gradient mx-1">{time.split(':')[1].slice(0, 2)}</span>
                    <span className="block mt-7 text-2xl">{time.split(' ')[1]}</span> {/* PM/AM next to hours */}
                </div>
            )}
            {/* Date and day displayed next to each other */}
            <div className="text-lg md:text-xl mt-2 flex justify-center">
                <span className="mr-2">{date.split(',')[0]}</span> {/* Day */}
                <span>{date.split(',')[1]}</span> {/* Month and Day Number */}
            </div>
        </div>
    );
};

export default ClockDisplay;
