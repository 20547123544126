import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineHome, HiOutlineDocumentText } from "react-icons/hi";
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import { HiOutlineArrowRightOnRectangle, HiBars3BottomRight, HiBars3BottomLeft } from "react-icons/hi2";

const Sidebar = ({ isOpen, toggleSidebar, handleLogout }) => {
    const location = useLocation();

    const isActive = (path) => location.pathname === path ? 'bg-gray-300' : '';

    // Close sidebar if the screen is smaller than 768px
    const closeSidebarIfSmallScreen = () => {
        if (window.innerWidth < 768) {
            toggleSidebar(false); // Close the sidebar on small screens
        }
    };

    useEffect(() => {
        // Optionally, you can also close the sidebar when resizing the window
        const handleResize = () => {
            if (window.innerWidth >= 768 && isOpen) {
                toggleSidebar(false); // Close sidebar if screen resizes to a larger screen
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isOpen, toggleSidebar]); // Include toggleSidebar in the dependencies

    return (
        <nav className={`w-30 transition-all bg-gray-100 text-black flex flex-col border border-l-4`}>
            <div className="flex items-center gap-16 px-4 py-4">
                {isOpen && (
                    <img
                        src="/profile.png"
                        alt="Drukair Logo"
                        className="w-10 h-10"
                    />
                )}
                <button onClick={toggleSidebar} className="text-2xl font-bold">
                    {isOpen ? <HiBars3BottomRight /> : <HiBars3BottomLeft />}
                </button>
            </div>

            <ul className="flex-1 space-y-4 px-2">
                <li>
                    <Link
                        to="/"
                        className={`flex items-center text-md hover:bg-white p-2 rounded ${isActive('/')}`}
                        onClick={closeSidebarIfSmallScreen}
                    >
                        <HiOutlineHome className="text-lg" />
                        <span className={`${isOpen ? 'ml-2' : 'hidden'} transition-all`}>Home</span>
                    </Link>
                </li>
                <li>
                    <Link
                        to="/chat-history"
                        className={`flex items-center text-md hover:bg-white p-2 rounded ${isActive('/chat-history')}`}
                        onClick={closeSidebarIfSmallScreen}
                    >
                        <HiOutlineDocumentText className="text-lg" />
                        <span className={`${isOpen ? 'ml-2' : 'hidden'} transition-all`}>History</span>
                    </Link>
                </li>
                <li>
                    <Link
                        to="/settings"
                        className={`flex items-center text-md hover:bg-white p-2 rounded ${isActive('/settings')}`}
                        onClick={closeSidebarIfSmallScreen}
                    >
                        <HiOutlineCog8Tooth className="text-lg" />
                        <span className={`${isOpen ? 'ml-2' : 'hidden'} transition-all`}>Settings</span>
                    </Link>
                </li>
            </ul>

            <button
                onClick={() => {
                    handleLogout();
                    closeSidebarIfSmallScreen(); // Close sidebar on logout
                }}
                className="text-black p-2 rounded hover:bg-white transition-all mb-4 flex items-center"
            >
                <HiOutlineArrowRightOnRectangle className="inline-block mr-2" />
                {isOpen && <span>Logout</span>}
            </button>
        </nav>
    );
};

export default Sidebar;
