import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiOutlineDownload } from 'react-icons/hi';
import ReactMarkdown from 'react-markdown';

const ChatHistory = () => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    // Fetch chat history from the backend
    const fetchChatHistory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/chat-history`);
            setConversations(response.data);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    useEffect(() => {
        fetchChatHistory();
    }, []);

    // Filter conversations by date range
    const filteredConversations = conversations.filter(conv => {
        const startedAt = new Date(conv.startedAt);
        return (!startDate || startedAt >= startDate) && (!endDate || startedAt <= new Date(endDate.setHours(23, 59, 59, 999)));
    });

    // Generate CSV data for all filtered conversations
    const generateCSV = () => {
        if (filteredConversations.length === 0) return;

        const data = [];
        data.push(['Date', 'Sender', 'Message']); // CSV Header

        filteredConversations.forEach(conv => {
            // Add a header for the conversation with the start date
            const conversationDate = new Date(conv.startedAt).toLocaleString();
            data.push([`Conversation started on: ${conversationDate}`, '', '']); // Add conversation header

            conv.messages.forEach(msg => {
                const date = new Date(msg.timestamp || conv.startedAt).toLocaleString();
                const sender = msg.sender === 'user' ? 'User' : 'Bot';
                const content = msg.content.replace(/"/g, '""'); // Escape double quotes
                data.push([date, sender, `"${content}"`]); // Enclose message in quotes
            });

            data.push(['', '', '']); // Add a blank row after each conversation for spacing
        });

        const csvContent = data.map(e => e.join(",")).join("\n");
        return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    };

    const downloadCSV = () => {
        const blob = generateCSV();
        if (!blob) {
            console.error("No data to download");
            return;
        }

        const url = URL.createObjectURL(blob);
        const filename = `chat_history_${new Date().toISOString().split('T')[0]}.csv`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex flex-col md:flex-row h-full gap-2">
            <div className="w-full md:w-[600px] h-full bg-gray-100 flex flex-col p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                <h1 className="text-2xl font-bold mb-4">Chat History</h1>

                <div className="flex items-center mb-4 justify-between">
                    <div className='flex items-center space-x-2'>
                        <p className="text-black text-sm font-semibold">Select Date Range</p>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => setDateRange(update)}
                            isClearable={true}
                            dateFormat="yyyy-MM-dd"
                            className="p-2 border border-gray-300 rounded w-[200px] h-[30px] text-sm"
                            placeholderText="Select a date range"
                        />
                    </div>
                    <button onClick={downloadCSV}>
                        <HiOutlineDownload className="text-xl" />
                    </button>
                </div>
                <div className="mb-4">
                    <p className="text-xs text-gray-500 mt-1">Recommended: Refresh your web browser once you reach the page to ensure accurate data.</p>
                </div>

                <div className="flex-1 space-y-2 overflow-y-auto">
                    {filteredConversations.slice().reverse().map((conv, index) => (
                        <div
                            key={index}
                            onClick={() => setSelectedConversation(conv)}
                            className="bg-white p-3 rounded-lg cursor-pointer shadow hover:shadow-md transition duration-200"
                        >
                            <div className="flex justify-between items-center mb-1">
                                <span className="text-black font-semibold text-md">
                                    {new Date(conv.startedAt).toLocaleString()}
                                </span>
                            </div>
                            <p className="text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                                {conv.messages.length > 0
                                    ? conv.messages[conv.messages.length - 1].content
                                    : 'No messages'}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-full md:w-[calc(100%-400px)] h-full bg-white p-6 rounded-lg overflow-hidden">
                <h2 className="text-xl font-bold mb-2">Conversation Details</h2>
                {selectedConversation ? (
                    <div className="flex flex-col h-full">
                        <div className="flex-shrink-0">
                            <p className="text-sm text-gray-600 mb-4">
                                Created on:
                                <span className="text-gray-600 text-sm gap-2">
                                    {new Date(selectedConversation.startedAt).toLocaleString()}
                                </span>
                            </p>
                            <hr className='mt-4' />
                        </div>

                        <div className="flex-1 overflow-y-auto mt-2 mb-6">
                            {selectedConversation.messages.map((msg, msgIndex) => (
                                <div key={msgIndex} className={`m-2 ${msg.sender === 'user' ? 'self-start' : 'self-end'}`}>
                                    <span className={`block text-xs font-bold mb-1 ${msg.sender === 'user' ? 'text-[#03bd12]' : 'text-blue-600'}`}>
                                        {msg.sender.charAt(0).toUpperCase() + msg.sender.slice(1)}
                                    </span>
                                    <div className={`p-3 rounded-lg ${msg.sender === 'user' ? 'bg-gray-100 text-black' : 'bg-blue-500 text-white'}`}>
                                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div>
                        <p className="text-gray-600">Select a conversation to view details.</p>
                        <hr className='mt-4' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatHistory;
