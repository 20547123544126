import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import ClockDisplay from './ClockDisplay'

const Login = ({ handleLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [currentTime, setCurrentTime] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleLoginSubmit = (e) => {
        e.preventDefault();

        // Predefined username and password
        const predefinedUsername = 'BOB';
        const predefinedPassword = 'BOB@2024!';

        if (username === predefinedUsername && password === predefinedPassword) {
            // Store authentication state in local storage
            localStorage.setItem('isAuthenticated', 'true');
            handleLogin(); // Call handleLogin to update state
            navigate('/'); // Redirect to home page
        } else {
            alert('Wrong authentication!');
        }
    };

    useEffect(() => {
        const updateClock = () => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';

            const formattedHours = hours % 12 || 12; // Convert to 12-hour format, using '12' for '0'
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

            setCurrentTime(`${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`);
        };

        const interval = setInterval(updateClock, 1000);
        return () => clearInterval(interval); // Clean up on component unmount
    }, []);

    const magneticEffect = {
        animate: {
            y: [0, -10, 0],  // Float up and down
        },
        transition: {
            duration: 5,
            ease: "easeInOut",
            repeat: Infinity, // Loop animation
            repeatType: "mirror",
        },
    };

    const cloudFloatingEffect1 = {
        animate: {
            y: [0, -18, 0],
        },
        transition: {
            duration: 3,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "mirror",
        },
    };


    const cloudFloatingEffect2 = {
        animate: {
            y: [0, 15, 0],
        },
        transition: {
            duration: 5,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "mirror",
        },
    };

    const cloudFloatingEffect3 = {
        animate: {
            y: [0, -16, 0],
        },
        transition: {
            duration: 6,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "mirror",
        },
    };

    const imageAnimation = {
        initial: { x: '-100%', opacity: 0 },  // Start from the left (off-screen)
        animate: { x: '0%', opacity: 1, transition: { duration: 2 } },  // Move to center (on-screen) with a slower duration
    };

    return (
        <div className="relative h-full overflow-hidden">
            <motion.div className="fixed inset-0">
                <img
                    src="/bg_mountain.png"
                    alt="Background Mountain"
                    className="w-full h-full object-cover"
                />
            </motion.div>

            {/* Display the current time at the top right corner */}
            <motion.div animate={cloudFloatingEffect3.animate} className="relative h-full">
                <ClockDisplay />
            </motion.div>

            <motion.div className="absolute top-[10%] lg:top-[-10%] right-5 w-full lg:mt-36 flex justify-center items-center">
                <motion.h1
                    animate={magneticEffect.animate}
                    transition={magneticEffect.transition}
                    className="text-7xl sm:text-[100px] xl:text-[230px] font-bold text-white opacity-90 leading-none mb-5 xl:mb-16">
                    <motion.img
                        src='/bob.png'
                        alt="BOB"
                        initial={imageAnimation.initial}
                        animate={imageAnimation.animate}
                    />
                </motion.h1>
            </motion.div>

            {/* Cloud images aligned at the bottom */}
            <motion.div className="z-[10] overflow-hidden absolute bottom-[2%] xl:bottom-[8%] left-0 flex justify-between items-end xl:transform xl:translate-y-1/3">
                <motion.img
                    animate={cloudFloatingEffect1.animate}
                    transition={cloudFloatingEffect1.transition}
                    src="/startCloud.png"
                    alt="Start Cloud"
                    className="w-full xl:w-1/2 transform translate-x-1/3"
                />
                <motion.img
                    animate={cloudFloatingEffect2.animate}
                    transition={cloudFloatingEffect2.transition}
                    src="/midCloud.png"
                    alt="Mid Cloud"
                    className="transform -translate-x-1/3 w-1/2"
                />
                <motion.img
                    animate={cloudFloatingEffect3.animate}
                    transition={cloudFloatingEffect3.transition}
                    src="/endCloud.png" alt="End Cloud"
                    className="w-1/2 transform -translate-x-2/3 "
                />
            </motion.div>

            <div className="absolute top-2 right-4">
                <motion.div animate={cloudFloatingEffect3.animate}
                    className="text-gray-800 text-center">
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="mt-4 px-6 py-2 text-gray-900 bg-white/5 backdrop-blur-lg border border-white/30 rounded-full hover:border-purple-400 hover:bg-white/20 transition z-[50]">
                        Login to Dashboard
                    </button>
                </motion.div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-20">
                    <div className="bg-white/20 backdrop-blur-lg p-6 rounded shadow-md w-80">
                        <h2 className="mb-4 text-lg font-bold text-center text-white">Login</h2>
                        <form onSubmit={handleLoginSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold mb-2 text-white" htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="w-full p-2 border border-white/30 bg-white/20 backdrop-blur-md text-white rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold mb-2 text-white" htmlFor="password">Password</label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full p-2 border border-white/30 bg-white/20 backdrop-blur-md text-white rounded"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute right-3 top-2 text-black"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            <button type="submit" className="w-full bg-blue-500/70 text-white p-2 rounded-lg hover:bg-blue-600/70">
                                Login
                            </button>
                            <button
                                type="button"
                                onClick={() => setIsModalOpen(false)}
                                className="mt-2 w-full text-white p-2">
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
