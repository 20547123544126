import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Dashboard';
import ChatHistory from './components/ChatHistory';
import Settings from './components/Settings';
import Sidebar from './components/Sidebar';
import Login from './components/Login';

const App = () => {
  const [isOpen, setIsOpen] = useState(true); // Sidebar open state
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    // Check local storage to see if the user is authenticated
    const auth = localStorage.getItem('isAuthenticated');
    if (auth === 'true') {
      setIsAuthenticated(true);
    }
    setIsLoading(false); // Set loading to false after checking authentication
  }, []);

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true'); // Save authentication state to local storage
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Clear the authentication state from local storage
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  return (
    <Router>
      <div className="flex h-screen">
        {isAuthenticated && <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} handleLogout={handleLogout} />}
        <div className="flex-1 p-6 bg-gray-100">
          <Routes>
            <Route
              path="/"
              element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path='/chat-history'
              element={isAuthenticated ? <ChatHistory /> : <Navigate to="/login" />}
            />
            <Route
              path='/settings'
              element={isAuthenticated ? <Settings /> : <Navigate to="/login" />}
            />
            <Route
              path='/login'
              element={isAuthenticated ? <Navigate to="/" /> : <Login handleLogin={handleLogin} />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;